/* ./src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

html {
	font-family: "Poppins", sans-serif;
}
.br-left-50 {
	left: 50%;
}
.leaflet-container {
	width: 91.666667%;
	margin: 0 auto;
	height: 300px;
	z-index: 2;
}

@layer base {
	/* prata-regular - latin */
	@font-face {
		font-family: "Prata";
		font-style: normal;
		font-weight: 400;
		src: local(""), url("./components/fonts/Prata/prata-v18-latin-regular.woff2") format("woff2"),
			/* Chrome 26+, Opera 23+, Firefox 39+ */ url("./components/fonts/Prata/prata-v18-latin-regular.woff")
				format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
	}

	/* didact-gothic-regular - latin */
	@font-face {
		font-family: "Didact Gothic";
		font-style: normal;
		font-weight: 400;
		src: local(""), url("./components/fonts/Didact_Gothic/didact-gothic-v19-latin-regular.woff2") format("woff2"),
			/* Chrome 26+, Opera 23+, Firefox 39+ */
				url("./components/fonts/Didact_Gothic/didact-gothic-v19-latin-regular.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
	}

	/* poppins-100 - latin */
	@font-face {
		font-family: "Poppins";
		font-style: normal;
		font-weight: 100;
		src: local(""), url("./components/fonts/Poppins/poppins-v20-latin-100.woff2") format("woff2"),
			/* Chrome 26+, Opera 23+, Firefox 39+ */ url("./components/fonts/Poppins/poppins-v20-latin-100.woff")
				format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
	}

	/* poppins-200 - latin */
	@font-face {
		font-family: "Poppins";
		font-style: normal;
		font-weight: 200;
		src: local(""), url("./components/fonts/Poppins/poppins-v20-latin-200.woff2") format("woff2"),
			/* Chrome 26+, Opera 23+, Firefox 39+ */ url("./components/fonts/Poppins/poppins-v20-latin-200.woff")
				format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
	}

	/* poppins-300 - latin */
	@font-face {
		font-family: "Poppins";
		font-style: normal;
		font-weight: 300;
		src: local(""), url("./components/fonts/Poppins/poppins-v20-latin-300.woff2") format("woff2"),
			/* Chrome 26+, Opera 23+, Firefox 39+ */ url("./components/fonts/Poppins/poppins-v20-latin-300.woff")
				format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
	}

	/* poppins-regular - latin */
	@font-face {
		font-family: "Poppins";
		font-style: normal;
		font-weight: 400;
		src: local(""), url("./components/fonts/Poppins/poppins-v20-latin-regular.woff2") format("woff2"),
			/* Chrome 26+, Opera 23+, Firefox 39+ */ url("./components/fonts/Poppins/poppins-v20-latin-regular.woff")
				format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
	}

	/* poppins-500 - latin */
	@font-face {
		font-family: "Poppins";
		font-style: normal;
		font-weight: 500;
		src: local(""), url("./components/fonts/Poppins/poppins-v20-latin-500.woff2") format("woff2"),
			/* Chrome 26+, Opera 23+, Firefox 39+ */ url("./components/fonts/Poppins/poppins-v20-latin-500.woff")
				format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
	}

	/* poppins-600 - latin */
	@font-face {
		font-family: "Poppins";
		font-style: normal;
		font-weight: 600;
		src: local(""), url("./components/fonts/Poppins/poppins-v20-latin-600.woff2") format("woff2"),
			/* Chrome 26+, Opera 23+, Firefox 39+ */ url("./components/fonts/Poppins/poppins-v20-latin-600.woff")
				format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
	}

	/* poppins-700 - latin */
	@font-face {
		font-family: "Poppins";
		font-style: normal;
		font-weight: 700;
		src: local(""), url("./components/fonts/Poppins/poppins-v20-latin-700.woff2") format("woff2"),
			/* Chrome 26+, Opera 23+, Firefox 39+ */ url("./components/fonts/Poppins/poppins-v20-latin-700.woff")
				format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
	}

	/* poppins-800 - latin */
	@font-face {
		font-family: "Poppins";
		font-style: normal;
		font-weight: 800;
		src: local(""), url("./components/fonts/Poppins/poppins-v20-latin-800.woff2") format("woff2"),
			/* Chrome 26+, Opera 23+, Firefox 39+ */ url("./components/fonts/Poppins/poppins-v20-latin-800.woff")
				format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
	}

	/* poppins-900 - latin */
	@font-face {
		font-family: "Poppins";
		font-style: normal;
		font-weight: 900;
		src: local(""), url("./components/fonts/Poppins/poppins-v20-latin-900.woff2") format("woff2"),
			/* Chrome 26+, Opera 23+, Firefox 39+ */ url("./components/fonts/Poppins/poppins-v20-latin-900.woff")
				format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
	}
}
